import { APOData, builderData, templateJSON } from "./BuilderProps";
import { GetAPI_deleteAPI_method } from "./Api";
import {
  getUrlParams,
  mouserOver_method,
  updateHandlebarsTemp,
} from "./CommonMethod";
import moment from "moment";

export const themeTypeSetting = async () => {
  const { isTabs, templatePageUnder } = templateJSON;

  if (templatePageUnder === "tabs") {
    const switchPage = document.querySelector(".switchPage");
    switchPage.remove();
  }

  // Initialize jQuery UI tabs with custom active class handling
  if (isTabs) {
    $(".TabWidget .widge-TabContant").tabs({
      active: 0, // Activate the first tab (index 0)
      activate: function (event, ui) {
        ui.oldTab.removeClass("active");
        ui.newTab.addClass("active"); 
      },
    });
  }
};

export const templateSetting = async () => {
  try {
    await updateOpeningHours(); // Update opening hours
    await updateContactData(); // Update contact details
    await CookieTermsAndPolicy(); // Update Cookie, Term And Policy
    await fetchBookingRules(); // Fetch and apply booking rules

    if (!parseInt(await getUrlParams("ThemeType"))) {
      await defaultTemplateStyle(); // Apply default template style
    }
  } catch (error) {
    console.error("Error in updating template settings:", error);
  }
};

const updateOpeningHours = async () => {
  try {
    const { businessHoursList } = APOData.onboardingData;
    const openingH = document.querySelector(".Bhours-list");
    const template = document.getElementById("template_Bhours").innerHTML;

    // Compile Handlebars template method
    await updateHandlebarsTemp(openingH, template, {
      businessHoursList,
      currentDay: moment(new Date()).format("dddd"),
    });
  } catch (error) {
    console.error("Error updating opening hours:", error);
  }
};

const updateContactData = async () => {
  try {
    const { businessAddressList } = APOData.onboardingData;
    if (!!businessAddressList || businessAddressList.length) {
      const addressSection = document.querySelector(".Baddress-detail");
      const template = document.getElementById("template_Baddress").innerHTML;

      // Compile Handlebars template method
      await updateHandlebarsTemp(addressSection, template, {
        businessAddressList: [businessAddressList[0]],
      });
    }
  } catch (error) {
    console.error("Error updating contact data:", error);
  }
};

const CookieTermsAndPolicy = async () => {
  try {
    // Fetch Privacy Policy, Terms, and Cookies data
    const response = await GetAPI_deleteAPI_method(
      "CookieTermsAndPolicy",
      "GET"
    );
    const { cookies, policy, terms } = response.data;

    const updateSection = async (sectionSelector, templateId, data, isEnabled) => {
      const sectionElement = document.querySelector(sectionSelector);
      sectionElement.style.display = isEnabled ? "" : "none";
      if (isEnabled) {
        const templateHTML = document.getElementById(templateId).innerHTML;
        await updateHandlebarsTemp(sectionElement, templateHTML, data);
    
        // Add custom class based on the section's dataset value if present
        if (sectionElement.dataset.class) {
          sectionElement.classList.add(sectionElement.dataset.class);
        }
      }
    }

    // Update Privacy Policy section
    await updateSection(".PrivacyPolicy-section", "template_PrivacyPolicy", policy, policy.isEnablePolicy);

    // Update Terms and Conditions section
    await updateSection(".Terms-section", "template_Terms", terms, terms.isEnableTerms);

    // Update Cookies section
    await updateSection(".Cookies-Section", "template_Cookies", cookies, cookies.isEnableCookies);

    // Remove entire section if all policies are disabled
    if (!policy.isEnablePolicy && !terms.isEnableTerms && !cookies.isEnableCookies) {
      const section = document.querySelector(`[data-section="CookieTermsAndPolicy"]`);
      if (section) {
        section.remove();
      }
    }
    else {
      await mouserOver_method(); // Show class on hover
    }
  } catch (error) {
    console.error("Error loading Privacy Policy or Terms data:", error);
  }
};

const fetchBookingRules = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("BookingRules", "GET");
    APOData.Bookingrules = response.data;
  } catch (error) {
    console.error("Error fetching booking rules:", error);
  }
};

export const filterBookingRule = async (allowedKeys) => {
  const { bookingRulesLists } = APOData.Bookingrules;

  if (allowedKeys.length > 1) {
    return bookingRulesLists.filter((rule) =>
      allowedKeys.includes(rule.keyName)
    );
  } else {
    return bookingRulesLists.find((rule) => rule.keyName === allowedKeys[0]);
  }
};

const defaultTemplateStyle = async () => {
  try {
    const response = await GetAPI_deleteAPI_method("TemplateSettings", "GET");

    if (response.status === 200) {
      const { data } = response;
      Object.assign(builderData.templateSetting, data);

      // Function to update fontFamily in StyleObj and button style
      await updateFontFamily(data.fontFamily);
      await buttonDefaultStyle(data);
    }

    const { brandBannerUrl, brandLogoUrl } = APOData.onboardingData;

    const brandBanner = document.querySelector(".BrandBanner");
    const brandLogo = document.querySelector(".BrandLogo");
    if (brandBannerUrl && brandBanner) {
      const whereApply = brandBanner.dataset.bg;

      brandBanner.style.backgroundImage =
        whereApply === "rowBG" ? `url(${brandBannerUrl})` : "none";
      brandBanner.querySelector(".row-content").style.backgroundImage =
        whereApply === "rowBG" ? "none" : `url(${brandBannerUrl})`;
    }
    if (brandLogoUrl && brandLogo) {
      brandLogo.querySelector("img").src = brandLogoUrl;
    }
  } catch (error) {
    console.error("Error fetching template style:", error);
  }
};

// Function to update fontFamily in StyleObj
const updateFontFamily = async (newFontFamily) => {
  const {StyleObj} = templateJSON;
  for (const [key, value] of Object.entries(StyleObj)) {
    // Update fontFamily in the main level
    if (value.fontFamily) {
      value.fontFamily = newFontFamily;
    }

    // Check nested objects for fontFamily
    for (const [subKey, subValue] of Object.entries(value)) {
      if (subValue?.fontFamily) {
        subValue.fontFamily = newFontFamily;
      }
    }
  }
};

const buttonDefaultStyle = async(styleData) => {
  const allButtons =  document.querySelectorAll(".mainContainerWrapper .buttonstyle");
  // Apply the JSON styles to each button
  allButtons.forEach(button => {
    button.style.color = `#${styleData.fontColor}`;
    button.style.backgroundColor = `#${styleData.buttonColor}`;
    button.style.fontSize = `${styleData.fontSize}px`;
    button.style.fontFamily = styleData.fontFamily;
  });
  
}
