import { v4 as uuidv4 } from "uuid";
// global variable
export let builderData = {
  editWidget: "",
  widgetName: "",
  mainCtnr: [],
  templateSetting: {},
  previewViewport: "desktop",
};
export let APOData = {
  onboardingData: null,
  Service: [],
  GlobalEvents: [],
  Class: [],
  APO_selectedData: {
    appointmentID: null,
    staffID: null,
    date: null,
    timeslot: null,
    appointmentJSON: null,
    staffJSON: null,
    serviceActiveStep: null,
  },
  APOtype: null,
  CountriesList: [],
};
export const templateJSON = {
  isTabs: false,
  templatePageUnder: "navbar",
  StyleObj: {
    BusinessHours: {
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#93908D",
        letterSpacing: "0px",
      },
    },
    BusinessAddress: {
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#086CD0",
        letterSpacing: "0px",
      },
    },
    PrivacyPolicy: {
      Titlestyling: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "130%",
        color: "#252525",
        letterSpacing: "0px",
      },
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#252525",
        letterSpacing: "0px",
      },
    },
    Terms: {
      Titlestyling: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "130%",
        color: "#252525",
        letterSpacing: "0px",
      },
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#252525",
        letterSpacing: "0px",
      },
    },
    Cookies: {
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#252525",
        letterSpacing: "0px",
      },
    },
    Service: {
      layout: "summary",
      steps: [
        { name: "locationlist", text: "Location" },
        { name: "servicelist", text: "Service" },
        { name: "stafflist", text: "Provider" },
        { name: "datetime", text: "Date & Time" },
        { name: "userdetails", text: "Information" },
      ],
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#777777",
        letterSpacing: "0px",
      },
      Titlestyling: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "130%",
        color: "#252525",
        letterSpacing: "0px",
      },
    },
    SelectedService: {
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#000",
        letterSpacing: "0px",
      },
      Titlestyling: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "130%",
        color: "#000",
        letterSpacing: "0px",
      },
    },
    OrderDetail: {
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "15px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#717b81",
        letterSpacing: "0px",
      },
      Titlestyling: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: "500",
        lineHeight: "130%",
        color: "#000",
        letterSpacing: "0px",
      },
    },
    stafflist: {
      Titlestyling: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "130%",
        color: "#252525",
        letterSpacing: "0px",
      },
    },
    teamList: {
      Titlestyling: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "130%",
        color: "#252525",
        letterSpacing: "0px",
      },
    },
    Calendar: {
      active: {
        backgroundColor: "#2F6CB5",
      },
    },
    AppointmentSlot: {
      Timeslots: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "150%",
        color: "#2F6CB5",
        letterSpacing: "0px",
        backgroundColor: "#FAFAFA",
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "6px",
      },
    },
    Signup: {
      form: {
        fields: [
          {
            inMobileView: "column",
            row: [
              {
                id: uuidv4(),
                type: "input",
                width: "100%",
                attributes: {
                  type: "text",
                  placeholder: "Enter your name",
                  name: "name",
                  required: true,
                },
                label: {
                  show: true,
                  text: "Name*",
                },
              },
            ],
          },
          {
            inMobileView: "column",
            row: [
              {
                id: uuidv4(),
                type: "input",
                width: "100%",
                attributes: {
                  type: "email",
                  placeholder: "Enter email address",
                  name: "email",
                  required: true,
                },
                label: {
                  show: true,
                  text: "Email*",
                },
              },
              {
                id: uuidv4(),
                type: "input",
                feildref: "SlotsQuantity",
                width: "160px",
                attributes: {
                  type: "number",
                  placeholder: "Enter booking Slots",
                  name: "SlotsQuantity",
                  required: true,
                },
                label: {
                  show: true,
                  text: "Book Slots*",
                },
              },
            ],
          },
          {
            inMobileView: "column",
            row: [
              {
                id: uuidv4(),
                type: "input",
                width: "100%",
                feildref: "inviteGuest",
                attributes: {
                  type: "email",
                  placeholder: "Enter guest email",
                  name: "inviteGuest",
                  required: true,
                },
                label: {
                  show: true,
                  text: "Add guests",
                },
              },
            ],
          },
          {
            inMobileView: "column",
            row: [
              {
                id: uuidv4(),
                type: "input",
                width: "100%",
                attributes: {
                  type: "number",
                  placeholder: "Enter phone number",
                  name: "phoneNumber",
                  required: true,
                },
                label: {
                  show: true,
                  text: "Phone no.*",
                },
                prefix: {
                  id: uuidv4(),
                  type: "select",
                  search: true,
                  attributes: {
                    name: "country_code",
                    required: true,
                  },
                  label: {
                    show: true,
                    text: "Country Code",
                  },
                  options: [
                    {
                      value: "select",
                      text: "Select country code",
                    },
                  ],
                },
              },
            ],
          },
          {
            inMobileView: "column",
            row: [
              {
                id: uuidv4(),
                type: "select",
                width: "100%",
                search: true,
                attributes: {
                  name: "country",
                  required: true,
                },
                label: {
                  show: true,
                  text: "Country",
                },
                options: [
                  {
                    value: "select",
                    text: "Select country*",
                  },
                ],
              },
            ],
          },
        ],
      },
      widgetstyle: {
        backgroundColor: "transparent",
        width: "100%",
        paddingTop: "0px",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
      },
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#252525",
        letterSpacing: "0px",
      },
      inputstyle: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "170%",
        height: "43px",
        color: "#000",
        letterSpacing: "0px",
        backgroundColor: "transparent",
        borderColor: "#D9D9D9",
        borderStyle: "solid",
        borderWidth: "0px 0px 1px 0px",
        borderRadius: "0px",
      },
    },
    Booking: {
      servicetitle: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "170%",
        color: "#252525",
        letterSpacing: "0px",
      },
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "170%",
        color: "#252525",
        letterSpacing: "0px",
      },
      borderStyle: {
        borderColor: "#EFEFEF",
        borderStyle: "solid",
        borderWidth: "0px",
        height: "0px",
      },
    },
    BookingSearch: {
      widgetstyle: {
        backgroundColor: "transparent",
        width: "100%",
      },
    },
    ReviewList: {
      Textstyling: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "150%",
        color: "#777777",
        letterSpacing: "0px",
      },
      Titlestyling: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "150%",
        color: "#252525",
        letterSpacing: "0px",
      },
    },
  },
};
globalThis.APOData = APOData;
globalThis.templateJSON = templateJSON;