// css file import
import '@fortawesome/fontawesome-free/css/all.css';
import "jquery-ui/themes/base/all.css";
import "jquery-custom-select/dist/css/jquery.custom-select.min.css";
import 'placeholder-loading/dist/css/placeholder-loading.min.css';
import "spectrum-colorpicker2/dist/spectrum.min.css";
// import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css';
import "./assets/css/Ck-Style.css"
import "./assets/css/editor_module.css";
import "./assets/css/common.css";
import "./assets/css/Theme1.css";
import "./assets/css/theme_widget.css";
import "./assets/css/fontFace.css";

// package's import
// import "@fortawesome/fontawesome-free/js/all.js";
import "jquery-ui/ui/widgets/accordion";
import "jquery-ui/ui/widgets/sortable";
import "jquery-ui/ui/widgets/tabs";
import "jquery-custom-select";
import "./assets/js/ThirdPartyLibraries";
import "bootstrap-datepicker";
import "./assets/ckeditor/build/ckeditor"
// import _ from "lodash";

// JS file import
import "./assets/js/Pageload";
import "./assets/js/index";
// import "./assets/js/PropertySetting";
import "./assets/js/Rowsetting";
import "./assets/js/Service";
import "./assets/js/S3config"
import "./assets/js/preview"

$(".custom-accordion").accordion({
  collapsible: true,
  heightStyle: "content",
});
$(".custom-Tabs").tabs({ active: 1 });
